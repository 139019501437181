import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Map from "../../content/imgs/icons/map-b.png";
import { ClientsContext } from "../../contexts/Context";
import Card from "../components/clientCard";

export default function Parque() {
  const location = useLocation();
  const { park } = location.state;
  const { brand = "", name = "" } = useParams();
  const navigate = useNavigate();
  const [cards, setCards] = useState<any>([]);
  const [cardsList, setCardsList] = useState<any>([]);
  const context = useContext(ClientsContext);
  const [toggle, setToggle] = useState(0);
  const toggleList = ["left-0", "left-1/2"];
  const [filter, setFilter] = useState("date");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (context?.clients && park && brand && name) {
      let cardsObject = context.clients.reduce((acc: any, card: any) => {
        if (
          card?.stats === "recolhido" &&
          card?.park &&
          (card?.park).split(":")[1].toLowerCase() === name.toLowerCase() &&
          (card?.park).split(":")[0].toLowerCase() === brand.toLowerCase()
        ) {
          const key = `${card.row}-${card.spot}`;
          if (key !== "-") {
            if (acc[key])
                acc[`${key}-${card.idClient}`] = card;
            else
                acc[key] = card;
          }
          else acc[card?.idClient] = card;
        }
        return acc;
      }, {});

      setCards(cardsObject);
    }
  }, [context?.clients, park?.name, park, name, brand]);

  useEffect(() => {
    if (Object.values(cards).length > 0) {
      let newCards: any = [];
      if (filter === "date") {
        newCards = Object.values(cards).sort((a: any, b: any) => {
          return compareDates(a.checkOut, b.checkOut);
        });
        setCardsList(newCards);
      } else if (filter === "key") {
        newCards = Object.keys(cards)
          .sort((a: string, b: string) => {
            const [rowA, spotA] = a.split("-").map(Number);
            const [rowB, spotB] = b.split("-").map(Number);
            if (rowA === rowB) {
              return spotA - spotB;
            }
            return rowA - rowB;
          })
          .map((key) => cards[key])
          .filter((card: any) => card.row || card.spot);
      } else if (filter === "withoutSpot") {
        // Filter by cars without row or spot
        newCards = Object.values(cards).filter((card: any) => !card.row || !card.spot);
      
      }
      // Search for
      if (search.trim() !== "") {
        newCards = newCards.filter((card: any) => {
          const searchTerm = search.toLowerCase();
          return (
            (card?.licensePlate)
              .toString()
              .toLowerCase()
              .includes(searchTerm) ||
            (card?.name).toString().toLowerCase().includes(searchTerm) ||
            (card?.alocation).toString().toLowerCase().includes(searchTerm) ||
            (card?.idClient).toString().toLowerCase().includes(searchTerm)
          );
        });
      }
      setCardsList(newCards);
    }
  }, [cards, filter, search]); // eslint-disable-line react-hooks/exhaustive-deps

  function parseDateString(dateString: string) {
    const [datePart, timePart] = dateString.split(", ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hours, minutes] = timePart.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes);
  }

  function compareDates(a: string, b: string) {
    const dateA: any = parseDateString(a);
    const dateB: any = parseDateString(b);
    return dateA - dateB;
  }

  function getSimpleDate(date: string) {
    const day = date.split("/")[0];
    const month = date.split("/")[1];
    // get time
    const [hours, minutes] = date.split(", ")[1].split(":");
    if (day === "NaN") return "Erro";
    return `${day}/${month}, ${hours}:${minutes}`;
  }

  function formatDate(date: Date) {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    return `${day}/${month}`;
  }

  function isToday(key: string) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const currentDate = formatDate(today);
    const tomorrowDate = formatDate(tomorrow);

    const card = cards[key];
    if (card) {
      const day = card.checkOut.split("/")[0];
      const month = card.checkOut.split("/")[1];
      const checkOutDate = `${day}/${month}`;

      if (checkOutDate === currentDate) {
        return "bg-[var(--red)] text-white"; // If the check-out date is today
      } else if (checkOutDate === tomorrowDate) {
        return "bg-[var(--yellow)] text-black"; // If the check-out date is tomorrow
      }
    }
    return "";
  }

  function checkTwoCars(rowIndex:any) {
    const carsInRow: any = Object.keys(cards)
    .sort((a: string, b: string) => {
      const [rowA, spotA] = a.split("-").map(Number);
      const [rowB, spotB] = b.split("-").map(Number);
      if (rowA === rowB) {
        return spotA - spotB;
      }
      return rowA - rowB;
    })
    .map((key) => cards[key]).filter(
      (card: any) => card.row.toString() === rowIndex.toString()
    );

    // Check for two cars in the same spot
    for (let i = 1; i < carsInRow.length; i++) {
      if (carsInRow[i].spot === carsInRow[i - 1].spot) {
        return "animate-bounce";
      }
    }
    return ""
  }

  function checkRow(rowIndex: number) {
    const carsInRow: any = Object.keys(cards)
    .sort((a: string, b: string) => {
      const [rowA, spotA] = a.split("-").map(Number);
      const [rowB, spotB] = b.split("-").map(Number);
      if (rowA === rowB) {
        return spotA - spotB;
      }
      return rowA - rowB;
    })
    .map((key) => cards[key]).filter(
      (card: any) => card.row.toString() === rowIndex.toString()
    );

    // Check for two cars in the same spot
    for (let i = 1; i < carsInRow.length; i++) {
      if (carsInRow[i].spot === carsInRow[i - 1].spot) {
        return "2 cars 1 spot";
      }
    }

    for (let i = 1; i < carsInRow.length; i++) {
      // check if car is stuck
      if (compareDates(carsInRow[i].checkOut, carsInRow[i - 1].checkOut) > 0 && carsInRow[i].checkOut !== carsInRow[i - 1].checkOut) {
        return "Carro preso";
      }
    }
    
    // Check for empty spots
    const maxSpot = Math.max(...carsInRow.map((car: any) => car.spot));
    const spots = new Set(carsInRow.map((car: any) => car.spot));
    for (let i = 1; i <= maxSpot; i++) {
      if (!spots.has(i.toString())) {
        return "Espaço vazio";
      }
    }

    return "";
  }

  function isUnavailable(row: number, spot: number) {
    return park.unavailableSpots.some(
      (unavailableSpot: any) =>
        unavailableSpot.row === row && unavailableSpot.spot === spot
    );
  }

  function getCell(row: number, spot: number, index: number) {
    const key: string = `${row}-${spot}`;
    const card = cards[key];
    if (isUnavailable(row, spot)) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-10 border-2 border-black text-[.8rem] bg-black"></div>
      );
    }
    if (!card) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-10 border-2 border-black text-[.8rem]"></div>
      );
    }
    return (
      <div
        key={index}
        onClick={() => {
          if (context?.user?.type === "Admin")
            navigate(`/consulta/${card.idClient}`);
          else navigate(`/movimentacao/${card.idClient}`);
        }}
        className={`flex flex-col items-center justify-center w-full h-10 border-2 border-black text-[.8rem] cursor-pointer ${checkTwoCars(row)} ${isToday(
          key
        )}`}
      >
        <p>{card.alocation}</p>
        <p>{getSimpleDate(card.checkOut)}</p>
      </div>
    );
  }

  if (!context || !park || !cards || !cardsList) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        className="absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 py-20 mil:overflow-x-auto cinco:py-8">
        <h1 className="text-[1.2rem] font-mybold text-[var(--primary)] text-center mb-8">
          PARQUE
        </h1>
        <p className="text-[1.5rem] font-mybold text-[var(--primary)] text-center">
          {park?.name}
        </p>
        <div className="flex flex-col items-center w-full gap-2">
          <p className="text-left text-[var(--primary)] font-bold w-full">
            Filtrar:
          </p>
          <select
            name="stats"
            value={filter}
            className="select"
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="date">Data de saída</option>
            <option value="key">Fila-Lugar</option>
            <option value="withoutSpot">Sem lugar</option>
          </select>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="| PESQUISA"
            className="input"
          />
        </div>

        <div className="relative flex items-center w-full h-8 border-2 border-black rounded-full justify-evenly">
          <div
            className={`absolute top-0 ${toggleList[toggle]} h-full w-1/2 bg-[var(--yellow)] rounded-full z-[-1] transition-all duration-300`}
          ></div>
          <p
            onClick={() => setToggle(0)}
            className="w-1/3 text-[.8rem] text-center cursor-pointer"
          >
            Lista
          </p>
          <p
            onClick={() => setToggle(1)}
            className="w-1/3 text-[.8rem] text-center cursor-pointer"
          >
            Mapa
          </p>
        </div>
        {cards && cardsList.length > 0 && (
          <p className="w-full font-bold text-center">
            {cardsList.length} carro
            {cardsList.length > 1 && "s"} no parque
          </p>
        )}
        {toggle === 0 ? (
          <div className="flex flex-col w-full gap-2">
            {cardsList.length !== 0 ? (
              cardsList.map((card: any, index: any) => {
                return (
                  <Card
                    key={index}
                    client={card}
                    href={"consulta"}
                    type={"parque"}
                  />
                );
              })
            ) : (
              <p className="text-center">Nenhum carro encontrado</p>
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full pb-8 overflow-x-auto">
            <div
              className={`grid w-full`}
              style={{
                gridTemplateColumns: `repeat(${
                  (park?.spots).length + 1
                }, 5.5rem)`,
              }}
            >
              <div className="flex items-center justify-center w-full h-10 border-2 border-black "></div>
              {park?.spots &&
                park?.spots.map((_: any, index: number) => (
                  <div
                    key={index}
                    className="flex items-center justify-center w-full h-10 border-2 border-black"
                  >
                    {index + 1}
                  </div>
                ))}
            </div>
            {park?.rows &&
              park?.rows.map((_: any, idx: number) => (
                <div
                  className={`grid w-full`}
                  key={idx}
                  style={{
                    gridTemplateColumns: `repeat(${
                      (park?.spots).length + 1
                    }, 5.5rem)`,
                  }}
                >
                  <div className="flex flex-col items-center justify-center w-full h-10 border-2 border-black ">
                    <p className="text-[.9rem]">{idx + 1}</p>
                    <p className="text-[.7rem]">{checkRow(idx + 1)}</p>
                  </div>
                  {park?.spots &&
                    park?.spots.map((_: any, index: number) =>
                      getCell(idx + 1, index + 1, index)
                    )}
                </div>
              ))}
          </div>
        )}
        <div
          onClick={() => window.open(park?.locationLink, "_blank")}
          className="flex items-center justify-center gap-4 mt-8 cursor-pointer"
        >
          <div className="w-[3rem] h-[3rem] flex justify-center items-center rounded-full p-2 bg-black">
            <img src={Map} alt="map" className="w-full invert" />
          </div>
          <p className="text-[1.5rem] font-mybold text-[var(--primary)] text-center">
            {park?.locationName}
          </p>
        </div>
      </div>
    </>
  );
}
