// PrintBookingsComponent.js
import React from "react";
import "./bookingPage.css";

const PrintBookingsComponent = React.forwardRef(
  ({ card, ref }: { card: any; ref: any }) => {
    return (
      <div
        ref={ref}
        className="w-full h-screen space-y-10 text-[1.5rem] flex justify-center items-center flex-col -rotate-90"
      >
        <div className="w-full space-y-20">
          <div className="flex items-start justify-between w-full gap-[18rem]">
            <div className="text-[2rem]">
              <strong className="text-center">ENTREGA</strong>
              <p className="text-center whitespace-nowrap">{card?.checkOut}</p>
            </div>
          </div>
            <div>
              <strong>VOO Nº</strong>
              <p>{card?.returnFlight || "Nenhum"}</p>
            </div>
          <div className="w-full text-center">
            <h1 className="text-[15rem]">{card?.alocation}</h1>
          </div>
        </div>
      </div>
    );
  }
);

export default PrintBookingsComponent;
