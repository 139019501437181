// PrintBookingsComponent.js
import React from "react";
import AirparkLogo from "../content/imgs/logos/airpark.png";
import RedParkLogo from "../content/imgs/logos/redpark.png";
import SkyparkLogo from "../content/imgs/logos/skypark.png";

import "./bookingPage.css";

const PrintBookingsComponent = React.forwardRef(
  ({ card, ref }: { card: any; ref: any }) => {
    function getLogo() {
      if (card?.parkBrand.toLowerCase() === "skypark") return SkyparkLogo;
      else if (card?.parkBrand.toLowerCase() === "airpark") return AirparkLogo;
      else if (card?.parkBrand.toLowerCase() === "redpark") return RedParkLogo;
      else return AirparkLogo;
    }

    return (
      <div
        ref={ref}
        className="w-full h-screen space-y-10 text-[1.5rem] flex justify-between flex-col"
      >
        <div className="space-y-10">
          <div className="flex justify-center w-full">
            <img src={getLogo()} alt="logo" className="w-[15rem]" />
          </div>
          <div className="flex items-center justify-between w-full">
            <div>
              <strong className="font-bold text-center">RECOLHA</strong>
              <p className="text-center">{card?.checkIn}</p>
            </div>
            <div>
              <strong className="text-center">ENTREGA</strong>
              <p className="text-center">{card?.checkOut}</p>
            </div>
          </div>
          {card?.returnFlight && (
            <div>
              <strong>VOO Nº</strong>
              <p>{card?.returnFlight}</p>
            </div>
          )}
          <div className="flex flex-col justify-center w-full space-y-6 text-center">
            <div>
              <h1 className="text-[9rem]">{card?.alocation}</h1>
            </div>
            <div>
              <h1><strong>Recolha/Entrega:</strong> {card?.deliveryName}</h1>
            </div>
            <div>
              <h1><strong>Matrícula:</strong> {card?.licensePlate}</h1>
            </div>
            <div>
              <h1><strong>Carro:</strong> {card?.carInfo}</h1>
            </div>
            <div>
              <p>
                <strong>Serviços Extra:</strong>{" "}
                {card?.extraServices.length
                  ? card?.extraServices
                      .map((service: any) => service.pt)
                      .join(", ")
                  : "Nenhum"}
              </p>
            </div>
          </div>
        </div>
        <div className="pb-10 space-y-10">
          <div className="border-t-2 border-black"></div>
          <div className="border-t-2 border-black"></div>
        </div>
      </div>
    );
  }
);

export default PrintBookingsComponent;
